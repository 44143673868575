import React, { ReactNode } from "react";
import styles from "./Breadcrumbs.module.scss";
import Link from "../Link/Link";
import Text from "../Text/Text";

const Breadcrumbs = ({ breadcrumbs = [] }: Props) => {
  if (!breadcrumbs || !breadcrumbs.length) {
    return null;
  }
  return (
    <div className={styles.root}>
      {breadcrumbs.map((b: Breadcrumb, i: number) => {
        if (b) {
          return (
            <React.Fragment key={i}>
              <Link to={b.href} className={styles.link}>
                <Text variant="breadcrumb" as="span">
                  {b.title}
                </Text>
              </Link>
              {i + 1 !== breadcrumbs.length && " / "}
            </React.Fragment>
          );
        }
        return undefined;
      })}
    </div>
  );
};

interface Props {
  breadcrumbs?: Breadcrumb[];
}

export interface Breadcrumb {
  title: ReactNode;
  href: string;
}

export default Breadcrumbs;
