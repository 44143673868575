import classNames from "classnames";
import React from "react";
import PlainButton from "../../Button/PlainButton/PlainButton";
import Text from "../../Text/Text";
import styles from "./StepperButton.module.scss";

interface StepperStyledButtonProps {
  onClick: () => void;
  text: string;
  bold?: boolean;
  disabled?: boolean;
  stepNumber?: number;
}

const StepperStyledButton = ({
  onClick,
  text,
  bold = false,
  disabled,
  stepNumber
}: StepperStyledButtonProps) => (
  <PlainButton className={styles.root} onClick={onClick} disabled={disabled}>
    <div
      className={classNames(
        styles.content,
        styles.active,
        !stepNumber && styles.withoutNumber
      )}
    >
      {stepNumber && (
        <Text className={styles.stepNumber} variant="defaultBold" as="span">
          {stepNumber}
        </Text>
      )}
      <Text
        className={styles.stepTitle}
        variant={bold ? "defaultBold" : "default"}
        as="span"
      >
        {text}
      </Text>
    </div>
  </PlainButton>
);

export default StepperStyledButton;
