import classNames from "classnames";
import { graphql } from "gatsby";
import React, { ReactNode } from "react";
import AnchorButton from "../../components/AnchorButton/AnchorButton";
import Breadcrumbs, {
  Breadcrumb
} from "../../components/Breadcrumbs/Breadcrumbs";
import Card from "../../components/Card/Card";
import CardList from "../../components/CardList/CardList";
import { Col, Row, Wrapper } from "../../components/Grid";
import Image, { ImageRatios } from "../../components/Image/Image";
import SocialLinks from "../../components/SocialLinks/SocialLinks";
import WithSpacing from "../../components/Spacing/Spacing";
import Text from "../Text/Text";
import GetText from "../../i18n/GetText";
import {
  ArticleCardFields,
  CardInternalLink,
  GenericCardFields,
  SanityPage
} from "../../model/common";
import {
  SanityLesson,
  SanityUnitOfWork,
  textIdsForUnit
} from "../../model/lessons";
import { urlForDocument } from "../../urls";
import { findLessonNumber } from "../../utils/downloads";
import { imageUrlBuilder } from "../../utils/images";
import UnitOfWorkSummary from "../UnitOfWorkSummary/UnitOfWorkSummary";
import styles from "./UnitOfWorkLayout.module.scss";

export const unitOfWorkRelatedField = graphql`
  fragment SanityUnitOfWorkRelatedField on SanityUnitOfWork {
    related {
      ... on SanityDocument {
        _id
        _type
      }
      ... on SanityMake {
        title
        description
        image {
          ...SanityImage
        }
        slug {
          current
        }
      }
      ... on SanityUnitOfWork {
        title
        description
        ageRange
        unitType
        image {
          ...SanityImage
        }
        contents {
          _id
        }
        slug {
          current
        }
      }
      ... on SanityPdCourse {
        title
        description
        image {
          ...SanityImage
        }
        slug {
          current
        }
      }
    }
  }
`;

interface UnitOfWorkLayoutProps {
  listings: SanityPage;
  lesson?: SanityLesson;
  unit: SanityUnitOfWork;
  details: ReactNode;
}

const UnitOfWorkLayout = ({
  listings,
  unit,
  lesson,
  details
}: UnitOfWorkLayoutProps) => {
  const { title } = lesson ?? unit;
  const breadcrumbs: Breadcrumb[] = [
    {
      href: urlForDocument(listings),
      title: listings.title
    },
    {
      href: urlForDocument(unit),
      title: unit.title
    }
  ];
  if (lesson) {
    breadcrumbs.push({
      href: urlForDocument(lesson),
      title: lesson.title
    });
  }

  const lessonIndex = !lesson ? -1 : findLessonNumber(unit, lesson) - 1;
  const nextLesson = unit.contents[lessonIndex + 1];
  const nextLessonUrl = nextLesson ? urlForDocument(nextLesson) : undefined;
  // Legacy data has > 3 but the template can't cope. Edu team will align over time.
  const relatedCapped = unit.related
    .map(item => ({
      _type: "cardInternalLink",
      reference: item as ArticleCardFields,
      _key: item._id
    }))
    .slice(0, 3);
  return (
    <Wrapper className={styles.root}>
      <Row justify="center">
        <Col md={10} sm={12} className={styles.preamble}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <SocialLinks title={title} />
        </Col>
      </Row>
      <Row justify="center">
        <Col md={10} sm={12}>
          <Card type="main" className={styles.card}>
            {lesson && lesson.image ? (
              <Image
                className={classNames(styles.image, styles.lessonImage)}
                image={lesson.image}
                widths={400}
                aspectRatio={ImageRatios.Landscape_4_3}
                corners="rounded"
              />
            ) : (
              <img
                className={classNames(styles.image, styles.unitIcon)}
                alt=""
                src={imageUrlBuilder.image(unit.image).url()!}
              />
            )}
            <div className={styles.header}>
              <Text variant="h1">{title}</Text>
              <Text className={styles.type} variant="default">
                <GetText
                  id={
                    lesson
                      ? textIdsForUnit(unit).lesson
                      : `unitType-${unit.unitType}`
                  }
                />
                {lesson && (
                  <>
                    {" "}
                    {lessonIndex + 1} of {unit.contents.length}
                  </>
                )}
              </Text>
            </div>
            <UnitOfWorkSummary
              className={styles.unitOfWorkSummary}
              unit={unit}
              lesson={lesson}
              callToAction={
                nextLessonUrl && (
                  <AnchorButton primary to={nextLessonUrl}>
                    {!lesson ? (
                      <>
                        <GetText id={textIdsForUnit(unit).lesson} />
                        &nbsp;1 details
                      </>
                    ) : unit.unitType === "designChallenge" ? (
                      "Next activity"
                    ) : (
                      "Next lesson"
                    )}
                  </AnchorButton>
                )
              }
            />
          </Card>
        </Col>
      </Row>
      <Row justify="center" className={styles.details}>
        <Col md={10} sm={12}>
          {details}
        </Col>
      </Row>
      {relatedCapped.length > 0 && (
        <Row justify="center" className={styles.related}>
          <Col md={10} sm={12}>
            <WithSpacing>
              <Card type="main" className={styles.card}>
                <Text variant="h2">You may also be interested in</Text>
                <CardList
                  node={{
                    items: relatedCapped as [
                      GenericCardFields | CardInternalLink
                    ],
                    layout: relatedCapped.length === 3 ? "stretch" : "tile",
                    readMore: false
                  }}
                  widths={[350, 270, 270]}
                />
              </Card>
            </WithSpacing>
          </Col>
        </Row>
      )}
    </Wrapper>
  );
};

export default UnitOfWorkLayout;
