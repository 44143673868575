import { default as React, ReactNode } from "react";
import { PageMetadata } from "../../model/metadata";

import { TranslationStrings } from "../../i18n/TranslationStringsContext";
import { SiteArea } from "../../utils/analytics";

import { WindowLocation } from "@reach/router";
import "@reach/skip-nav/styles.css";
import { GlobalMenus } from "../../model/menu";
import PageLayoutContent from "../PageLayoutContent/PageLayoutContent";
import Providers from "../Providers/Providers";

export interface PageLayoutProps {
  menus: GlobalMenus;
  children: ReactNode;
  metadata: PageMetadata;
  location: WindowLocation;
  strings: TranslationStrings;
  siteArea: SiteArea;
  isErrorPage?: boolean;
}

const PageLayout = ({
  location,
  strings,
  siteArea,
  children,
  metadata,
  menus,
  isErrorPage = false
}: PageLayoutProps) => {
  const language = metadata.page.language ?? "en";
  return (
    <Providers
      language={language}
      location={location}
      menus={menus}
      strings={strings}
      siteArea={siteArea}
    >
      <PageLayoutContent
        menus={menus}
        metadata={metadata}
        children={children}
        isErrorPage={isErrorPage}
      />
    </Providers>
  );
};

export default PageLayout;
