import React from "react";
import Text from "../../Text/Text";
import StepperStyledButton from "../StepperButton/StepperStyledButton";
import styles from "./StepperBar.module.scss";

interface StepperBarInternalProps {
  text?: string;
  buttonText: string;
  onClick: () => void;
  stepNumber?: number;
}

const StepperBarInternal = ({
  text,
  buttonText,
  onClick,
  stepNumber
}: StepperBarInternalProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.nextStep}>
        <Text variant="toolbarDefaultBold">{text}</Text>
        <StepperStyledButton
          stepNumber={stepNumber}
          text={buttonText}
          onClick={onClick}
          bold
        />
      </div>
    </div>
  );
};

export default StepperBarInternal;
