import { WindowLocation } from "@reach/router";
import classNames from "classnames";
import { graphql, navigate } from "gatsby";
import upperFirst from "lodash.upperfirst";
import React, { useCallback } from "react";
import AnchorButton from "../components/AnchorButton/AnchorButton";
import BlockContent from "../components/BlockContent/BlockContent";
import Credits from "../components/Credits/Credits";
import Image, { ImageRatios } from "../components/Image/Image";
import WithSpacing from "../components/Spacing/Spacing";
import StepperBarInternal from "../components/Stepper/StepperBar/StepperBarInternal";
import SubjectTopicBadges from "../components/SubjectTopicBadges/SubjectTopicBadges";
import Tabs from "../components/Tabs/Tabs";
import Text from "../components/Text/Text";
import UnitOfWorkLayout from "../components/UnitOfWorkLayout/UnitOfWorkLayout";
import GetText, { useString } from "../i18n/GetText";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { SanityPage, SanityCampaign, SanitySkill } from "../model/common";
import { CommonContext } from "../model/common";
import {
  SanityLesson,
  SanityUnitOfWork,
  textIdsForUnit
} from "../model/lessons";
import { GlobalMenus } from "../model/menu";
import { urlForDocument } from "../urls";
import { SiteArea } from "../utils/analytics";
import { findLessonNumber } from "../utils/downloads";
import styles from "./UnitOfWorkPage.module.scss";

export const pageQuery = graphql`
  query UnitOfWorkPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    listings: sanityUnitOfWorkListingPage {
      _id
      _type
      title
    }
    unit: sanityUnitOfWork(_id: { eq: $_id }) {
      ...SanityUnitOfWorkForListing
      _rawIntroduction(resolveReferences: { maxDepth: 5 })
      _rawCurriculumLinks(resolveReferences: { maxDepth: 5 })
      additionalSkills {
        _id
        name
      }
      description
      ageRange
      programmingLanguages
      ...UnitOfWorkTopics
      slug {
        current
      }
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      contents {
        _id
        _type
        title
        slug {
          current
        }
        _rawIntroduction(resolveReferences: { maxDepth: 5 })
        _rawLearningObjectives(resolveReferences: { maxDepth: 5 })
        ...LessonTopics
        image {
          ...SanityImage
        }
        downloads {
          name
          category
          file {
            asset {
              originalFilename
              extension
            }
          }
        }
      }
      ...SanityUnitOfWorkRelatedField
      licence {
        ...Licence
      }
    }
  }
`;

const UnitOfWorkPage = ({
  pageContext,
  data: { menus, unit, listings },
  location
}: UnitOfWorkPageProps) => {
  const { title } = unit;
  return (
    <PageLayout
      siteArea={SiteArea.LESSONS}
      metadata={{
        title,
        alternates: pageContext.alternates,
        page: unit
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <UnitOfWorkLayout
        listings={listings}
        unit={unit}
        details={<UnitOfWorkDetails value={unit} />}
      />
    </PageLayout>
  );
};

const UnitOfWorkDetails = ({ value }: { value: SanityUnitOfWork }) => {
  const firstLesson = value.contents[0];
  const handleClick = useCallback(() => {
    navigate(urlForDocument(firstLesson));
  }, [firstLesson]);
  const lessonNumber = findLessonNumber(value, firstLesson);
  const typeName = useString(textIdsForUnit(value).lesson);
  const stepperBar = (
    <StepperBarInternal
      buttonText={`${typeName} ${lessonNumber} details`}
      onClick={handleClick}
    />
  );
  return (
    <Tabs
      node={{
        _key: "unit-of-work-tabs",
        content: [
          {
            title: "Summary",
            content: <Summary value={value} stepperBar={stepperBar} />,
            contentIsRendered: true
          },
          {
            title: "Curriculum links",
            content: (
              <>
                <BlockContent content={value._rawCurriculumLinks} />
                {stepperBar}
              </>
            ),
            contentIsRendered: true
          }
        ],
        layout: "landingPage",
        useTabButtons: true
      }}
    />
  );
};

const Summary = ({
  value,
  stepperBar
}: {
  value: SanityUnitOfWork;
  stepperBar: JSX.Element;
}) => {
  return (
    <>
      <WithSpacing>
        <div>
          <Text variant="h3">Overall key learning</Text>
          <BlockContent content={value._rawLearningObjectives} cap="2/3" />
          <AdditionalSkills additionalSkills={value.additionalSkills} />
          {value.contents.map(lesson => {
            return <LessonSummary unit={value} lesson={lesson} />;
          })}
          <Credits licence={value.licence} />
        </div>
      </WithSpacing>
      {stepperBar}
    </>
  );
};

const AdditionalSkills = ({
  additionalSkills
}: {
  additionalSkills: SanitySkill[];
}) => (
  <>
    {additionalSkills && !!additionalSkills.length && (
      <>
        <Text variant="h3">
          <GetText id="additional-skills" />
        </Text>
        <Text
          className={styles.cap23}
          variant="defaultLight"
          gutterBottom={true}
        >
          {additionalSkills.map((skill, index) => (
            <span key={skill.name}>
              {index === 0 ? upperFirst(skill.name) : skill.name}
              {index < additionalSkills.length - 1 && ", "}
            </span>
          ))}
          {"."}
        </Text>
      </>
    )}
  </>
);

const LessonSummary = ({
  lesson,
  unit
}: {
  lesson: SanityLesson;
  unit: SanityUnitOfWork;
}) => {
  const lessonNumber = findLessonNumber(unit, lesson);
  const typeName = <GetText id={textIdsForUnit(unit).lesson} />;
  return (
    <WithSpacing>
      <section>
        <Text variant="h3">
          {typeName} {lessonNumber}: {lesson.title}
        </Text>
        <div className={styles.lessonContentMediaPair}>
          <WithSpacing>
            <div className={styles.lessonText}>
              <BlockContent content={[lesson._rawIntroduction[0]]} />
              {lesson.image && (
                <Image
                  className={classNames(
                    styles.lessonImage,
                    styles.showOnMobile
                  )}
                  image={lesson.image}
                  widths={400}
                  aspectRatio={ImageRatios.Landscape_4_3}
                  corners="rounded"
                />
              )}
              <Text variant="defaultBold">Key learning:</Text>
              <BlockContent
                content={lesson._rawLearningObjectives}
                className={styles.keyLearningBlock}
              />
              <SubjectTopicBadges topics={lesson.topics} />
            </div>
          </WithSpacing>
          {lesson.image && (
            <Image
              className={classNames(styles.lessonImage, styles.showOnDesktop)}
              image={lesson.image}
              widths={400}
              aspectRatio={ImageRatios.Landscape_4_3}
              corners="rounded"
            />
          )}
        </div>
        <AnchorButton to={urlForDocument(lesson)}>
          {typeName}&nbsp;{lessonNumber}&nbsp;details
        </AnchorButton>
      </section>
    </WithSpacing>
  );
};

export default UnitOfWorkPage;

interface UnitOfWorkPageProps {
  data: {
    menus: GlobalMenus;
    listings: SanityPage;
    unit: SanityUnitOfWork;
    campaigns: { nodes: SanityCampaign[] };
  };
  location: WindowLocation;
  pageContext: CommonContext & { allHexFileNames: string[] };
}
